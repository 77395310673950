'use strict';
app.factory('mapService',
        function ($rootScope, $http, $q, $ocLazyLoad) {
            const urlGISAPI = $rootScope["giswebApiurl"];
            const urlMasterAPI = $rootScope["masterWebApiUrl"];
            const urlGISGet = {
                harvesting: "Map/RawHarvesting",
                backlog: "Map/RawBacklog",
                harInsp: "Map/RawHarvestingInspection",
                gwiInsp: "Map/RawGeneralWorkInspection",
                fieldInsp: "Map/RawFieldInspection",
                harInspImg: "Map/HarvestingInspectionImage",
                gwiInspHistory: "Map/GWInspectionHistory",
                fieldInspImg: "Map/FieldInspectionImage",
                userAccessInfo: "Mapinfo/GetUserAccessInfo",
                qcUser: "QuartoConnectUserSetup/GetAllUsers",
                fieldInspHistory: "Map/FieldInspectionHistory",
                fieldInspCategory: "Map/FieldInspectionCategory"
            }
            const urlGISGetPaths = Object.fromEntries(Object.entries(urlGISGet).map(a => [a[0], `${urlGISAPI}/${a[1]}`]));

            const urlGetMaster = {
                blockInfo: "Map/BlockGeopointSetup/GetBlocks"
            }
            const urlMasterGetPaths = Object.fromEntries(Object.entries(urlGetMaster).map(a => [a[0], `${urlMasterAPI}/${a[1]}`]));

            const scriptJs = {
                geoxml3: "geoxml3.js",
                xmlwriter: "XMLWriter.js",
                markerclusterer: "markerclusterer.js",
                markerclustererx: "markerclustererx.js",
                mapmarkerlabel: "mapmarkerlabel.js",
                maplabels: "maplabels.js",
                mapboundsrender: "mapboundsrender.js",
                geostats: "geostats.min.js"
            };
            const scriptJsPaths = Object.fromEntries(Object.entries(scriptJs).map(a => [a[0], `scripts/${a[1]}`]));

            const cdn = {
                colorbox: "https://cdnjs.cloudflare.com/ajax/libs/jquery.colorbox/1.6.4/jquery.colorbox-min.js",
                spiderfier: "https://cdnjs.cloudflare.com/ajax/libs/OverlappingMarkerSpiderfier/1.0.3/oms.min.js"
            }

            const GEOSERVER_URL = "https://geoserver.quarto.cloud:8443/geoserver/";
            const GEOSERVER_WMS_VERSION = "1.1.1";
            const GOOGLE_MAP_API_VERSION = "3.52";

            const getLatestRawCropBacklogControl = (objParam) => $http.get(urlGISGetPaths.backlog, { params: objParam });
            const getLatestRawCropQualityInspectionControl = (objParam) => $http.get(urlGISGetPaths.harInsp, { params: objParam });
            const getHarvestingInspectionImage = (objParam) => $http.get(urlGISGetPaths.harInspImg, { params: objParam });
            const getLatestRawGeneralWorkInspection = (objParam) => $http.get(urlGISGetPaths.gwiInsp, { params: objParam });
            const getGeneralWorkInspectionImage = (objParam) => $http.get(urlGISGetPaths.gwiInspHistory, { params: objParam });
            const getLatestRawFieldInspection = (objParam) => $http.get(urlGISGetPaths.fieldInsp, { params: objParam });
            const getFieldInspectionCategory = (objParam) => $http.get(urlGISGetPaths.fieldInspCategory, { params: objParam });
            const getFieldInspectionImage = (objParam) => $http.get(urlGISGetPaths.fieldInspImg, { params: objParam });
            const getFieldInspectionHistory = (objParam) => $http.get(urlGISGetPaths.fieldInspHistory, { params: objParam });
            const getLatestCropHarvesting = (objParam) => $http.get(urlGISGetPaths.harvesting, { params: objParam });

            window.initGoogleMap = () => {
                console.log("Google Map JS API is loaded and available");
            }
            /**
             * @see https://github.com/ocombe/ocLazyLoad/issues/170
             * @see https://stackoverflow.com/questions/51442761/you-have-included-the-google-maps-javascript-api-multiple-times-on-this-page-th     
             * * @returns {Promise<void>}
             */
            async function lazyLoadGoogleMapAPI() {
                const params = [
                    "key=AIzaSyCIb_BZSfCLAnOE8loETE47qlDq42jwcKQ",
                    "libraries=visualization,geometry",
                    `v=${GOOGLE_MAP_API_VERSION}`,
                    "callback=initGoogleMap"
                ];

                const urlGoogleMapApis = "https://maps.googleapis.com/maps/api/js?" + params.join("&");
                await $ocLazyLoad.load({type: "js", path: urlGoogleMapApis});
            }

            const lazyLoadGeoXML3 = () => $ocLazyLoad.load(scriptJsPaths.geoxml3);
            const lazyLoadXMLWriter = () => $ocLazyLoad.load(scriptJsPaths.xmlwriter);
            const lazyLoadMarkerClusters = () => $ocLazyLoad.load(scriptJsPaths.markerclusterer);
            const lazyLoadMarkerClustererX = () => $ocLazyLoad.load(scriptJsPaths.markerclustererx);
            const lazyLoadMapLabel = () => $ocLazyLoad.load(scriptJsPaths.maplabels);
            const lazyLoadMapMarkerLabel = () => $ocLazyLoad.load(scriptJsPaths.mapmarkerlabel);
            const lazyLoadMapLabels = () => $ocLazyLoad.load(scriptJsPaths.maplabels);
            const lazyLoadMapBoundsRender = () => $ocLazyLoad.load(scriptJsPaths.mapboundsrender);
            const lazyLoadColorBox = () => $ocLazyLoad.load(cdn.colorbox);
            const lazyLoadSpiderfier = () => $ocLazyLoad.load(cdn.spiderfier);
            const lazyLoadGeoStats = () => $ocLazyLoad.load(scriptJsPaths.geostats);
           
            async function lazyLoadLibs() {
                await $.when(lazyLoadGoogleMapAPI(), lazyLoadColorBox(), lazyLoadGeoXML3(), lazyLoadXMLWriter(), lazyLoadMarkerClusters(), lazyLoadSpiderfier());
                //why load this separately? Coz is dependent on google map; MarkerClustererX depends on MarkerClusterer.
                await $.when(lazyLoadMapLabel(), lazyLoadMapMarkerLabel(), lazyLoadMarkerClustererX(), lazyLoadMapLabels(), lazyLoadMapBoundsRender(), lazyLoadGeoStats());
            }

            /**
             * @typedef MapService~UserAccessInfo~Client
             * @property {int} ClientKey
             * @property {string} ClientCode
             */

            /**
             * @typedef MapService~UserAccessInfo~OU
             * @property {int} OUKey
             * @property {string} OUCode
             */

            /**
             * @typedef MapService~UserAccessInfo
             * @property {MapService~UserAccessInfo~Client} Client - Client info of current user.
             * @property {MapService~UserAccessInfo~OU[]} OUList - List of OU which current user has access to.
             */

            /**
             * @returns {HttpPromise<MapService~UserAccessInfo>}
             */
            function getUserAccessInfo() {
                return $http.get(urlGISGetPaths.userAccessInfo);
            }

            function getBlockInfo(ouKey) {
                var deferred = $q.defer();
                $http.post(urlMasterGetPaths.blockInfo, { FilterBlockKeys: [] }).then((result) => {
                    result.data = result.data.filter(a => a.OUKey == ouKey && a.CenterPoint);
                    deferred.resolve(result);
                }, (error) => {
                    deferred.reject(error);
                })
                return deferred.promise;
            }

            function getQCUser(ouKey) {
                var strFilter = "$filter=ouKey+eq+" + ouKey;
                var url = ouKey ? (urlGISGetPaths.qcUser + "?" + strFilter) : urlGISGetPaths.qcUser;
                return $http.get(url);
            }

            function getCustomOverlayBasemap({myMap, clientWorkspace, ou, layerName}) {
                var tileWidth = 256;
                var tileHeight = 256;
                //In order to support old version
                var mapLayerName = ou ? ou : layerName;
                //Define custom WMS layer for census output areas in WGS84
                var overlayLayer =
                    new google.maps.ImageMapType(
                        {
                            getTileUrl: (coord, zoom) => {
                                var s = Math.pow(2, zoom);
                                //latlng bounds of the 4 corners of the google tile
                                //Note the coord passed in represents the top left hand (NW) corner of the tile.
                                var gBl = myMap.getProjection().fromPointToLatLng(
                                    new google.maps.Point(coord.x * tileWidth / s, (coord.y + 1) * tileHeight / s)); // bottom left / SW
                                var gTr = myMap.getProjection().fromPointToLatLng(
                                    new google.maps.Point((coord.x + 1) * tileWidth / s, coord.y * tileHeight / s)); // top right / NE

                                // Bounding box coords for tile in WMS pre-1.3 format (x,y)
                                var bbox = `${gBl.lng()},${gBl.lat()},${gTr.lng()},${gTr.lat()}`;
                                var client = `${clientWorkspace}/`;
                                var geoserverWMSService = "wms?";
                                var layers = `${clientWorkspace}%3A${mapLayerName}`;
                                var others = [
                                    `SERVICE=WMS`,
                                    `VERSION=${GEOSERVER_WMS_VERSION}`,
                                    `REQUEST=GetMap`,
                                    `FORMAT=image%2Fvnd.jpeg-png8`,
                                    `TRANSPARENT=true`,
                                    `LAYERS=${layers}`,
                                    `SRS=EPSG%3A4326`,
                                    `STYLES=&WIDTH=${tileWidth}&HEIGHT=${tileHeight}`,
                                    `&BBOX=${bbox}`
                                ]

                                var url = `${GEOSERVER_URL}${client}${geoserverWMSService}${others.join("&")}`;
                                return url;                 //return WMS URL for the tile  
                            }, //getTileURL

                            tileSize: new google.maps.Size(tileWidth, tileHeight),
                            opacity: 0.85
                        });
                return overlayLayer;
            }

            function getIsGeoserverLayerReady({clientWorkspace, ou, layerName}) {
                //In order to support old version
                var mapLayerName = ou ? ou : layerName;
                var deferred = $q.defer();
                var client = `${clientWorkspace}/`;
                var geoserverWMSService = "wms?";
                var layers = `${clientWorkspace}%3A${mapLayerName}`;
                var others = [
                    `SERVICE=WMS`,
                    `VERSION=${GEOSERVER_WMS_VERSION}`,
                    `REQUEST=GetMap`,
                    `LAYERS=${layers}`
                ]

                var url = `${GEOSERVER_URL}${client}${geoserverWMSService}${others.join("&")}`;
                fetch(url).then((res) => {
                    if (!res.ok) {
                        deferred.reject();
                        return;
                    }
                    res.text().then((xmlString) => {
                        var parser = new DOMParser();
                        var xmlDoc = parser.parseFromString(xmlString, "text/xml");
                        var elements = xmlDoc.getElementsByTagName('ServiceException');
                        for (var i = 0; i < elements.length; i++) {
                            if (elements[i].getAttribute('code') == "LayerNotDefined")
                                deferred.reject();
                        }
                        deferred.resolve(1);
                    })
                }, (err) => {
                    deferred.reject();
                });
                return deferred.promise;
            }

            /**
             * Check given layer exists.
             * @param {string} workspace - Workspace name.
             * @param {string} layer - Layer name.
             * @returns {Promise<boolean>} True if exists, other else False.
             * @exception Any error raised when contacting server will be thrown directly.
             * @exception {Error} `workspace` or `layer` is not defined.
             */
            async function hasGeoserverLayer({workspace, layer}) {
                if (!_.every([workspace, layer]))
                    throw new Error("workspace and layer are required fields");
                
                const url = `${GEOSERVER_URL}${encodeURI(workspace)}/wms?service=WMS&version=${GEOSERVER_WMS_VERSION}&request=GetMap&exceptions=application/json&layers=${encodeURI(layer)}`;
                const resp = await fetch(url);
                if (resp.ok) {
                    /**
                     * Sample exception when Layer not found
                     * { "version": "1.1.0", "exceptions": [ { "code": "LayerNotDefined", "locator": "layers", "text": "Could not find layer my_workspace:dest" } ]
                     */
                    const exceptions = _.get(await resp.json(), "exceptions", []);
                    return _.findIndex(exceptions, ex => _.get(ex, "code") === "LayerNotDefined") === -1;
                }
                return false;
            }

            return {
                getLatestCropHarvesting,
                getLatestRawCropBacklogControl,
                getLatestRawCropQualityInspectionControl,
                getLatestRawGeneralWorkInspection,
                getLatestRawFieldInspection,
                getFieldInspectionCategory,
                getHarvestingInspectionImage,
                getGeneralWorkInspectionImage,
                getFieldInspectionImage,
                getFieldInspectionHistory,
                lazyLoadGoogleMapAPI,
                lazyLoadLibs,
                lazyLoadMapLabels,
                lazyLoadColorBox,
                getUserAccessInfo,
                getBlockInfo,
                getQCUser,
                getCustomOverlayBasemap,
                getIsGeoserverLayerReady,
                hasGeoserverLayer
            }
        });

app.factory('mapUtil',  function (MAPCONSTANT, broadcastService) {

    function getCropBacklogConfigs() {
        const getClusterPathFromFile = (file) => `/content/img/map/clusterer/${file}`;
        const getBasicPathFromFile = (file) => `/content/img/map/pins/${file}`;
        const getClusterStyles = (imagePath, textColor, sizeAdjustRatio, anchorYTextRatioFromCenter) => {
            const SIZES = {
                1: { HEIGHT: 53 * sizeAdjustRatio, WIDTH: 53 * sizeAdjustRatio },
                2: { HEIGHT: 56 * sizeAdjustRatio, WIDTH: 56 * sizeAdjustRatio },
                3: { HEIGHT: 66 * sizeAdjustRatio, WIDTH: 66 * sizeAdjustRatio },
                4: { HEIGHT: 78 * sizeAdjustRatio, WIDTH: 78 * sizeAdjustRatio },
                5: { HEIGHT: 90 * sizeAdjustRatio, WIDTH: 90 * sizeAdjustRatio },
            }
            return [1, 2, 3, 4, 5].map((value, index) => {
                const height = SIZES[value].HEIGHT;
                const width = SIZES[value].WIDTH;
                return {
                    url: imagePath,
                    height,
                    width,
                    textColor,
                    anchorText: [height * anchorYTextRatioFromCenter, 0]
                };
            });
        }
        const getClusterConfig = (file, txtColor, sizeAdjustRatio = 1, anchorYTextRatioFromCenter = 0) => {
            let imagePath = getClusterPathFromFile(file);
            return {
                imagePath: imagePath.slice(0, -4),
                maxZoom: 17,
                styles: getClusterStyles(imagePath, txtColor, sizeAdjustRatio, anchorYTextRatioFromCenter)
            };
        };
        const getBasicConfig = (file, txtColor, sizeXY, labelOriginXY) => {
            return {
                url: getBasicPathFromFile(file),
                txtColor,
                size: sizeXY,
                labelOrigin: labelOriginXY
            }
        }

        const SIZEXY_30x30 = [30, 30];
        const SIZEXY_40x40 = [40, 40];
        const SIZEXY_50x50 = [50, 50];
        const SIZEXY_60x60 = [60, 60];
        const LABELXY_15x15 = [15, 15];
        const LABELXY_20x20 = [20, 20];
        const LABELXY_25x25 = [25, 25];
        const LABELXY_30x30 = [30, 30];
        const SCALE_0_5 = 0.5;
        const SCALE_0_8 = 0.8;
        const SCALE_0_9 = 0.9;
        const SCALE_1_0 = 1.0;
        return {
            Infield: {
                allDays: {
                    clusterConfig: getClusterConfig("if-0.svg", "white", SCALE_0_5),
                    basicConfig: getBasicConfig("if-0.svg", "white", SIZEXY_30x30, LABELXY_15x15)
                }
            },
            Platform: {
                day1: {
                    clusterConfig: getClusterConfig("pf-1.svg", "white", SCALE_0_8),
                    basicConfig: getBasicConfig("pf-1.svg", "white", SIZEXY_40x40, LABELXY_20x20)
                },
                day1_2: {
                    clusterConfig: getClusterConfig("pf-2.svg", "black", SCALE_0_8),
                    basicConfig: getBasicConfig("pf-2.svg", "black", SIZEXY_40x40, LABELXY_20x20)
                },
                day3_4: {
                    clusterConfig: getClusterConfig("pf-3.svg", "black", SCALE_0_8),
                    basicConfig: getBasicConfig("pf-3.svg", "black", SIZEXY_40x40, LABELXY_20x20)
                },
                day5_7: {
                    clusterConfig: getClusterConfig("pf-4.svg", "white", SCALE_0_8),
                    basicConfig: getBasicConfig("pf-4.svg", "white", SIZEXY_40x40, LABELXY_20x20)
                },
                day7plus: {
                    clusterConfig: getClusterConfig("pf-5.svg", "white", SCALE_0_8),
                    basicConfig: getBasicConfig("pf-5.svg", "white", SIZEXY_40x40, LABELXY_20x20)
                }
            },
            Bin: {
                day1: {
                    clusterConfig: getClusterConfig("bn-1.svg", "white", SCALE_0_9),
                    basicConfig: getBasicConfig("bn-1.svg", "white", SIZEXY_50x50, LABELXY_25x25)
                },
                day1_2: {
                    clusterConfig: getClusterConfig("bn-2.svg", "black", SCALE_0_9),
                    basicConfig: getBasicConfig("bn-2.svg", "black", SIZEXY_50x50, LABELXY_25x25)
                },
                day3_4: {
                    clusterConfig: getClusterConfig("bn-3.svg", "black", SCALE_0_9),
                    basicConfig: getBasicConfig("bn-3.svg", "black", SIZEXY_50x50, LABELXY_25x25)
                },
                day5_7: {
                    clusterConfig: getClusterConfig("bn-4.svg", "white", SCALE_0_9),
                    basicConfig: getBasicConfig("bn-4.svg", "white", SIZEXY_50x50, LABELXY_25x25)
                },
                day7plus: {
                    clusterConfig: getClusterConfig("bn-5.svg", "white", SCALE_0_9),
                    basicConfig: getBasicConfig("bn-5.svg", "white", SIZEXY_50x50, LABELXY_25x25)
                }
            },
            Ramp: {
                withinDay14: {
                    clusterConfig: getClusterConfig("rp-1.svg", "white"),
                    basicConfig: getBasicConfig("rp-1.svg", "white", SIZEXY_60x60, LABELXY_30x30)
                }
            }
        };
    }

    function getBacklogMapClusterConfigs() {
        var iconsUrl = {
            BacklogCluster1: "/content/img/map/clusterer/g",
            BacklogCluster1_2: "/content/img/map/clusterer/y",
            BacklogCluster2_3: "/content/img/map/clusterer/o",
            BacklogCluster3_7: "/content/img/map/clusterer/r",
            BacklogCluster7_14: "/content/img/map/clusterer/dr",
        };
        var configs = {
            backlog1: {
                imagePath: iconsUrl.BacklogCluster1,
                maxZoom: 17,
                styles: getStyles(iconsUrl.BacklogCluster1, "black")
            },
            backlog1_2: {
                imagePath: iconsUrl.BacklogCluster1_2,
                maxZoom: 17,
                styles: getStyles(iconsUrl.BacklogCluster1_2, "black")
            },
            backlog2_3: {
                imagePath: iconsUrl.BacklogCluster2_3,
                maxZoom: 17,
                styles: getStyles(iconsUrl.BacklogCluster2_3, "black")
            },
            backlog3_7: {
                imagePath: iconsUrl.BacklogCluster3_7,
                maxZoom: 17,
                styles: getStyles(iconsUrl.BacklogCluster3_7, "white")
            },
            backlog7_14: {
                imagePath: iconsUrl.BacklogCluster7_14,
                maxZoom: 17,
                styles: getStyles(iconsUrl.BacklogCluster7_14, "white")
            }
        };
        return configs;

        function getStyles(baseUrl, textColor) {
            const SIZES = {
                1: { HEIGHT: 53, WIDTH: 53 },
                2: { HEIGHT: 56, WIDTH: 56 },
                3: { HEIGHT: 66, WIDTH: 66 },
                4: { HEIGHT: 78, WIDTH: 78 },
                5: { HEIGHT: 90, WIDTH: 90 },
            }
            var styles = [1, 2, 3, 4, 5].map((value, index) => {
                const height = SIZES[value].HEIGHT;
                const width = SIZES[value].WIDTH;
                return {
                    url: `${baseUrl}.svg`,
                    height,
                    width,
                    textColor,
                    anchorText: [height / 4, 0]
                };
            });

            return styles;
        }

        return clusters;
    }

    function getKMLCircleString(longitude, latitude, radius) {
        var DEGREE = 20;
        var ALTITUDE = 1;
        var points = [];
        for (var i = 0; i < 360; i += DEGREE) {
            var radian = i * Math.PI / 180;
            var point = (longitude - radius * Math.cos(radian)) + ","
                + (latitude + radius * Math.sin(radian)) + ","
                + ALTITUDE;
            points.push(point);
        }
        return points.join(" ");
    }

    function getHTMLStarRating(rating) {
        var htmlOneStar = "<i class='fa fa-star starStyle' aria-hidden='true'></i>";
        var htmlTwoStar = htmlOneStar + htmlOneStar;
        var htmlThreeStar = htmlTwoStar + htmlOneStar;
        var htmlFourStar = htmlThreeStar + htmlOneStar;
        var htmlFiveStar = htmlFourStar + htmlOneStar;

        return rating == 1 ? htmlOneStar :
            rating == 2 ? htmlTwoStar :
                rating == 3 ? htmlThreeStar :
                    rating == 4 ? htmlFourStar :
                        rating == 5 ? htmlFiveStar : "";
    }

    function getIconUrls() {
        /**
         * Image sources:
         * clusterer - https://github.com/googlemaps/v3-utility-library/tree/master/markerclusterer/images
         * pins - https://maps.google.com/mapfiles/ms/icons/{filename}
         * push-pins - https://maps.google.com/mapfiles/ms/micons/{filename}
         **/
        return {
            HR_LESS_THAN_24: "/content/img/map/pins/green.png",
            HR_24_TO_48: "/content/img/map/pins/yellow.png",
            HR_MORE_THAN_48: "/content/img/map/pins/red.png",
            HAR_INSP_GOOD: "/content/img/map/pins/green.png",
            HAR_INSP_NORMAL: "/content/img/map/pins/yellow.png",
            HAR_INSP_BAD: "/content/img/map/pins/red.png",
            Red_Pushpin: "/content/img/map/pushpins/red-pushpin.png",
            Green_Pushpin: "/content/img/map/pushpins/grn-pushpin.png",
            Yellow_Pushpin: "/content/img/map/pushpins/ylw-pushpin.png",
            GoogleClusterPins: "/content/img/map/clusterer/cm",
            Backlog1: "/content/img/map/pins/backlog1.svg",
            Backlog1_2: "/content/img/map/pins/backlog1_2.svg",
            Backlog2_3: "/content/img/map/pins/backlog2_3.svg",
            Backlog3_7: "/content/img/map/pins/backlog3_7.svg",
            Backlog7_14: "/content/img/map/pins/backlog7_14.svg",
            EstateOffice: "/content/img/map/pins/estate-office.png"
        };
    }

    function getGwiIcons() {
        return {
            DW: {
                1: "dw1star.png",
                2: "dw2star.png",
                3: "dw3star.png",
                4: "dw4star.png",
                5: "dw5star.png"
            },
            PR: {
                1: "pr1star.png",
                2: "pr2star.png",
                3: "pr3star.png",
                4: "pr4star.png",
                5: "pr5star.png"
            }
        };
    }

    function getIsMobile() {
        return navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone|iPad|iPod/i);
        ;
    }

    function getSlickSliderSetting() {
        return {
            infinite: true,
            dots: true,
            speed: 500,
            useTransform: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 3000,
            arrows: true
        }
    }

    function reInitColorBox(imageTags) {
        $.colorbox.remove();
        $.each(imageTags, (index, imageTag) => {
            $(imageTag).colorbox({
                rel: 'images',
                href: $(imageTag).attr('src'),
                photo: true
            });
        });
    }

    return {
        getBacklogMapClusterConfigs,
        getKMLCircleString,
        getHTMLStarRating,
        getIconUrls,
        getIsMobile,
        getGwiIcons,
        getSlickSliderSetting,
        getCropBacklogConfigs,
        reInitColorBox
    };
});